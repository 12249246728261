import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

import "react-toastify/dist/ReactToastify.css";
import './SignUp.scss'
import axios from "axios";

const SignUp = (props) => {
    const [spinner, setSpinner] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmpassword, setconfirmPassword] = useState("");
    const [nameError, setnameError] = useState("");
    const [emailError, setemailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmpasswordError, setconfirmPasswordError] = useState("");
    const location = useLocation();

useEffect(()=>{
console.log("Location in Signup", location.state);
},[])

const handlePayment = async (planName, price) => {
    const stripe = await loadStripe(
      "pk_test_51P8VaoEGthoWj151dGGYesBtg7QRzfSvRFSLU0pcGnOyJqfWqo3V7lcyA4dUhyrhw85A6B7hZxs2KRBVGvjMzWl3002E4GRFWd"
    );
    try {
      const userEmail = email;
      if (!userEmail) {
        window.alert("Please log in first.");
        navigate("/signin"); // Use navigate instead of history.push
        return;
      }


      // Remove dollar sign and parse price string to float
      const parsedPrice = parseFloat(price.replace("$", ""));

      const payload = {
        email: userEmail,
        amount: parsedPrice * 4,
        planName: planName,
        base_url: window.location.origin,
      };
      console.log(payload)
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/create_payment_checkout_session`,
        payload
      );
      const session = response.data;
      console.log(session.sessionId, "session");
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });
      console.log("result", result);
      if (result.error) {
        console.log(result.error);
      }
    } catch (e) {
      console.log("Error Making a post request to Stripe Route", e);
    }
  };

    const navigate = useNavigate();

    const isValidEmail = (email) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }

    const isValidName = (name) => {
        const pattern = /^[A-Za-z\s]+$/;
        return pattern.test(name);
    }

    const handleSignUp = async () => {
        if (nameError.status && emailError.status && passwordError.status && confirmpasswordError.status) {
            setSpinner(true);
            const userData = {
                username: name,
                email: email,
                password: password
            };
            try {
                const response = await fetch(`${process.env.REACT_APP_BASEURL}/Auth/register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(userData)
                });
                if (response.ok) {
                    setSpinner(false);
                    // notify("Sign Up Successful", "success");
                    // toast.success("Successfully signed up",{toastId: "signinSuccess"})
                    if (location.state.label && location.state.price){

                        handlePayment(location.state.label, location.state.price)
                    }
                    else{

                        navigate("/signin");
                    }
                } else {
                    setSpinner(false);
                    const data = await response.json();
                    notify(data.message, "error");
                }
            } catch (error) {
                setSpinner(false);
                notify("An error occurred. Please try again later.", "error");
            }
        }
    };

    useEffect(() => {
        setnameError(
            name === "" ? "Required*" : isValidName(name) ? { data: "", status: true } : "Only alphabets are allowed"
        );
        setemailError(
            email === "" ? "Required*" : isValidEmail(email) ? { data: "", status: true } : "Invalid email format"
        );
        setPasswordError(
            password === "" ? "Required*" : password.length < 8 ? "Password must be 8 characters long*" : { data: "", status: true }
        );
        setconfirmPasswordError(
            confirmpassword === "" ? "Required*" : confirmpassword !== password ? "Passwords don't match" : { data: "", status: true }
        );
    }, [name, email, password, confirmpassword]);

    const notify = (message, type = "success") => {
        toast[type](message, {
            position: "top-right",
            autoClose: 2000,
        });
    };

    return (
        <>
            <div className="background-account">
                <div className="signupPage">
                    <div className="signin-content">
                        <div className="signup-card">
                            <div className="signupcard-content">
                                <div className="main-card">
                                    <div className="cardtitle">
                                        <div className="leftside">
                                            <h6>Sign Up</h6>
                                        </div>
                                    </div>

                                    <div className="card-field">
                                        <div className="firstfield-signup">
                                            <div className="label">
                                                <label htmlFor="">Enter your Name</label>
                                                <span className="span">{nameError.status ? nameError.data : nameError}</span>
                                            </div>
                                            <div className="inputfield">
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    value={name}
                                                    onChange={(e) => {
                                                        setName(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="firstfield">
                                            <div className="label">
                                                <label htmlFor="">Enter your email address</label>
                                                <span className="span">{emailError.status ? emailError.data : emailError}</span>
                                            </div>
                                            <div className="inputfield">
                                                <input
                                                    type="text"
                                                    placeholder="Email address"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="firstfield">
                                            <div className="label">
                                                <label htmlFor="">Enter your Password</label>
                                                <span className="span">{passwordError.status ? passwordError.data : passwordError}</span>
                                            </div>
                                            <div className="inputfield">
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="firstfield">
                                            <div className="label">
                                                <label htmlFor="">Confirm your Password</label>
                                                <span className="span">{confirmpasswordError.status ? confirmpasswordError.data : confirmpasswordError}</span>
                                            </div>
                                            <div className="inputfield">
                                                <input
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    value={confirmpassword}
                                                    onChange={(e) => {
                                                        setconfirmPassword(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-bottom">
                                        <div className="bottom-title">
                                            <p>
                                                Already have an Account?
                                                <span onClick={() => navigate("/signin")}>Log In</span>
                                            </p>
                                        </div>
                                        <div className="signin-btn">
                                            <button onClick={handleSignUp}>
                                                
                                                {spinner ? <span>Loading...</span>: "Sign Up"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUp;
