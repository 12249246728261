import Hero from "./components/home/hero";
import HowToUse from "./components/home/howToUse";
import KeyFeatures from "./components/home/keyFeatures";
import Pricing from "./components/home/pricing";
import React from "react";
import "../webapp.css";
import Footer from "../component/shared/footer";
import Navbar from "../component/shared/navbar";
import JobSearch from "./components/home/jobSearch";
import Subscribe from "./components/home/subscribe";
import Testimonial from "./components/home/testimonial";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <HowToUse />
      <KeyFeatures />
      <Pricing />
      <Testimonial />
      <Subscribe />
      <JobSearch />
      <Footer />
    </>
  );
};

export default Home;
