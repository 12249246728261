import React, { useState } from "react";
import "./resetpassword.scss";
// import logo from "../../../assets/icons/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const resetPassword = async () => {
        try {
            // Check if email is provided
            if (!email) {
                toast.error("Please enter your registered email");
                return;
            }
            console.log("entered Email", email);
            // Assuming your API endpoint for password reset is '/reset-password'
            const url = `${process.env.REACT_APP_BASEURL}/Auth/request_reset`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: email }), // stringify the body
            });

            if (response.ok) {
                // Handle success, like showing a success message to the user
                localStorage.setItem("email", email);
                toast.success("Reset password email sent successfully!");
                navigate("/otp");
            } else {
                toast.error("No user found with this email.");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Error sending reset password email. Please try again later.");
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    return (
        <div className="reset-password-main">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="reset-password-content">
                <h2>Reset Password</h2>
                <input
                    type="text"
                    name="email"
                    autoComplete="off"
                    placeholder="Enter your registered email"
                    value={email}
                    onChange={handleEmailChange}
                />

                <div className="buttons">
                    <Link to="/signIn">
                        <div className="go-back-btn white-button">Go Back</div>
                    </Link>
                    <div className="submit-button red-button btn-primary" style={{cursor:'pointer'}} onClick={resetPassword}>
                        Submit
                    </div>
                </div>

                <div className="sign-in">
                    <p>Already have an account?</p>
                    <Link to="/signIn"> Sign in</Link>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
