import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import videoSrc from  "../../../assets/videos/Sequence.mp4"
import { useNavigate } from 'react-router-dom';



const HowToUseModal = (props) => {
    const navigate = useNavigate();
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        How it works
      </Modal.Title>
    </Modal.Header>
    <Modal.Body style={{padding: "20px"}}>
      <h4>Instructions to install extension</h4>
      <p>You have to install Zenpply Indeed Extension First. Here's the video guide on how to install the extension.</p>
      <video className="img-fluid" controls>
              <source src={videoSrc} type="video/mp4"></source>
              </video>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={()=>navigate("/applyindeed")}>Start Apply</Button>
    </Modal.Footer>
  </Modal>
  )
}

export default HowToUseModal