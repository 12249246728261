import React, { useState } from "react";
import "./resetpassword.scss";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ConfigPassword = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formValues.password !== formValues.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const { token, password, confirmPassword } = formValues;

    const url = `${process.env.REACT_APP_BASEURL}/Auth/set_new_password`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          new_password: password,
          confirm_password: confirmPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        navigate("/signIn");
      } else {
        toast.error(data.detail || "Failed to reset password. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="reset-password-main">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="reset-password-content">
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit} className="new-pass">
          <input
            className="email-field"
            name="token"
            type="text"
            autoComplete="off"
            placeholder="Enter Token"
            value={formValues.token}
            onChange={handleChange}
          />
          <input
            className="email-field"
            name="password"
            type="password"
            autoComplete="off"
            placeholder="Enter New Password"
            value={formValues.password}
            onChange={handleChange}
          />
          <input
            className="email-field"
            name="confirmPassword"
            type="password"
            autoComplete="off"
            placeholder="Confirm New Password"
            value={formValues.confirmPassword}
            onChange={handleChange}
          />

          <div className="buttons">
            <button type="submit" className="submit-button red-button btn-primary" style={{cursor:'pointer'}}>
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfigPassword;
