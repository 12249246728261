import React from 'react'
import './FAQ.scss'

const FAQ = () => {
    return (
        <div className='faq-main-container'>
            <div className='faq-inner-container'>
                <h2>Frequently asked questions</h2>
                <div className='questionare-inner-container'>
                    <div className='questionare-inner-box'>
                        <p>"Do You have 1.5 -2 years experience in Figma, Adobe XD, Sketch?"</p>
                        <textarea cols={30} rows={5} />
                    </div>
                    <div className='questionare-inner-box'>
                        <p>"Have you worked in an ecommerce environment as UXUI Designer?"</p>
                        <textarea cols={30} rows={5} />
                    </div>
                    <div className='questionare-inner-box'>
                        <p>"Have you worked in an ecommerce environment as UXUI Designer?"</p>
                        <textarea cols={30} rows={5} />
                    </div>
                </div>
                <button className="continue-button" >
                    Submit
                </button>
            </div>
        </div>
    )
}

export default FAQ