import React from "react";
import "./sliderCards.scss";

const SliderCards = (data) => {
  return (
    <>
      <div className="testimonial-cards   gap-3">
        <div>
          
          <img src={data.sliderImg} alt="" className="rounded-circle" />
        </div>

        <div className="right-content d-flex flex-column align-items-start">
          <h6>{data.name}</h6>
          <p>{data.details}</p>

        </div>
      </div>
    </>
  );
};

export default SliderCards;
