import axios from "axios";
import Header from "../Components/Header";
import React, { useState } from "react";
import { useEffect } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Indeed = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const userEmail = localStorage.getItem('email');
  const [job, setJob] = useState("");
  const [location, setLocation] = useState("");
  const [numberOfJobs, setNumberOfJobs] = useState(
    localStorage.getItem("numberOfJobs") || ""
  );

  useEffect(() => {
    localStorage.setItem("numberOfJobs", numberOfJobs);
  }, [numberOfJobs]);

  const handleInputChange = (event) => {
    setNumberOfJobs(event.target.value);
  };

  function constructJobSearchUrl(jobType, jobLocation) {
    // Replace spaces with plus signs
    var formattedJobType = jobType.replace(/ /g, "+");
    var formattedJobLocation = jobLocation.replace(/ /g, "+");

    // Construct the URL
    var baseUrl = "https://pk.indeed.com/jobs?q=";
    var url =
      baseUrl +
      encodeURIComponent(formattedJobType) +
      "&l=" +
      encodeURIComponent(formattedJobLocation) +
      "&a=" +
      numberOfJobs +
      "&user_id=" +
      123;

    return url;
  }

  const handleUrl = () => {
    console.log(job);
    console.log(location);
    const result = constructJobSearchUrl(job, location);
    console.log(result);
    localStorage.setItem("jobSearchUrl", result);
    const link = document.createElement("a");
    link.href = result;
    link.target = "_blank";
    link.click();
  };

  // const fetchFileName = () => {
  //   const apiUrl = `${process.env.REACT_APP_BASEURL}/get_filename`;
  //   axios
  //     .post(apiUrl, null, {
  //       params: {
  //         user_email: userEmail,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Received file name:", response.data.filename);
  //       setFileName(response.data.filename);
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred:", error);
  //     });
  // };
  // useEffect(() => {
  //   fetchFileName();
  //   console.log("Check Kr wa", fileName)
  // }, []);

  return (
    <>
      <ToastContainer />

      <div className="container-fluid">
        {/* <Header /> */}
        <div className="d-flex justify-content-between py-4">
          <div className="col-6 d-flex flex-column justify-content-center">
            <h1 className="font-weight-bold text-lg">Start Applying on Indeed</h1>
          </div>
          <button
            onClick={() => navigate("../Dashboard")}
            type="submit"
            className="flex btn btn-primary border border-dark px-4 rounded-lg hover-bg-dark hover-text-white"
            style={{ width: 'fit-content', height: 'fit-content' }}
          >
            <IoIosArrowRoundBack size={25} className="mr-2" />
            Go Back
          </button>
        </div>


        <div className="row justify-content-between">
          {/* <div className="col-6">
            <label className="text-gray-600 font-weight-bold ">
              Enter The Skill/Skills or Tags
            </label>
            <input
              type="text"
              className="form-control my-4 border-gray-300 text-gray-900 text-sm rounded-lg focus-ring-blue focus-border-blue"
              required
            />
            <label className="text-gray-600 ">
              For example You can enter Single Tag like Full Stack Developer or
              Multiple Tag like Java php etc.
            </label>
          </div> */}
          <div className="col-6">
            <label className="text-secondary fw-bold">
            Enter the Job Location (Optional)
            </label>
            <input
              type="text"
              className="form-control mt-2 mb-1 border-gray-300 text-gray-900 text-sm rounded-lg focus-ring-blue focus-border-blue"
              id="job location"
              onChange={(e) => setLocation(e.target.value)}
            />
            <label className="text-gray-600">
            For example, you can enter your job location: Atlanta, GA.
            </label>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6">
            <label className="text-secondary fw-bold">
            Number of Jobs to Apply
            </label>
            <input
              type="text"
              value={numberOfJobs}
              onChange={handleInputChange}
              className="form-control mt-2 mb-1 border-gray-300 text-gray-900 text-sm rounded-lg focus-ring-blue focus-border-blue"
              required
            />
            <label className="text-gray-600">
            For example, enter the number of jobs to apply for, like 50 or 100.
            </label>
          </div>
        </div>
        <div className="row mt-4">
          {/* <div className="col-md-6">
            <label className="text-gray-600 font-weight-bold">
              Experience Level
            </label>
            <select
              id="countries"
              className="form-select my-4 border-gray-300 text-gray-900 text-sm rounded-lg focus-ring-blue focus-border-blue"
            >
              <option selected>Select Experience Level</option>
              <option value="-1">Less than One Year</option>
              <option value="1">One Year</option>
              <option value="+1">More Than One Year </option>
            </select>
          </div> */}
          <div className="col-md-6">
            <label className="text-secondary fw-bold">
            Enter the Job You Want to Search
            </label>
            <input
              type="text"
              className="form-control mt-2 mb-1 border-gray-300 text-gray-900 text-sm rounded-lg focus-ring-blue focus-border-blue"
              id="job type"
              onChange={(e) => setJob(e.target.value)}
            />
            <label className="text-gray-600">
            For example, you can enter your job title, like Full Stack Developer or Software Engineer
            </label>
          </div>
        </div>

        <div className="mt-4 d-flex justify-content-start align-items-start">
          <button
            onClick={handleUrl}
            type="submit"
            className="btn btn-primary mt-8"
            style={{ width: 'fit-content', padding: '10px 20px' }}
          >
            Start Applying
          </button>
        </div>
      </div>
    </>
  );
};

export default Indeed;
