import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.scss";
import dashIcon from "../../assets/dashboard/dashIcon.png";
import faq from "../../assets/dashboard/faq.png";
import profile from "../../assets/dashboard/profile.png";
import tick from "../../assets/dashboard/tick.png";
import logos from "../../assets/home/navLogo.png";
import FAQ from "./FAQ/FAQ";
import FrontDash from "./FrontDash/FrontDash";
import Home from "./Home/Home";
import JobSubmitted from "./JobSubmitted/JobSubmitted";
import LinkedinJob from "./LinkedinJob/LinkedinJob";
import EditProfile from "./Profile/EditProfile";
import ResumeUpload from "./ResumeUpload/ResumeUpload";
import { CgProfile } from "react-icons/cg";
import { CiSettings, CiLogout } from "react-icons/ci";
import { FaLinkedin } from "react-icons/fa6";
import {
  MdOutlineHome,
  MdOutlineFeedback,
  MdOutlineDoneAll,
} from "react-icons/md";
import { SiIndeed } from "react-icons/si";
import { NavLink, Navigate, useNavigate } from "react-router-dom";

function SideBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("Home");
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    }
  }, []);
  if (!token) {
    return null
  }
  return (
    <div>
      <div
        className={`dashboard-left-container sidebar pe-3 ${isSidebarOpen ? "open" : ""
          }`}
      >
        <div className="text-center mt-10">
          <img className="h-center mx-auto my-3" src={logos} alt="" />

          <ul>
            <NavLink className="navlinks" to="/dashboard">
              <li>
                <img src={dashIcon} alt="" />
                <span>Dashboard</span>
              </li>
            </NavLink>

            <NavLink className="navlinks" to="/profile">
              <li>
                <img src={profile} alt="" />
                <span>Profile</span>
              </li>
            </NavLink>

            <NavLink className="navlinks" to="/submited">
              <li>
                <img src={tick} alt="" />
                <span>Submitted</span>
              </li>
            </NavLink>

            <NavLink className="navlinks" to="/faqs">
              <li>
                <img src={faq} alt="" />
                <span>Faqs</span>
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
