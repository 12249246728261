import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import { IoIosArrowRoundBack } from "react-icons/io";
import axios from "axios";
import ExperienceDropdown from "./ExperienceDropdown/ExperienceDropdown";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Linkedin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [fileName, setFileName] = useState("");
  const userEmail = localStorage.getItem('email');

  const navigate = useNavigate();

  const handleApplyJobs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/applyToJobs/`
      );
      setResult(response.data.result);
    } catch (err) {
      setError(err.message);
      toast.error("An error occurred while applying to jobs.");
    } finally {
      setLoading(false);
    }
  };

  // const fetchFileName = () => {
  //   const apiUrl = `${process.env.REACT_APP_BASEURL}/get_filename`;

  //   axios
  //     .post(apiUrl, null, {
  //       params: {
  //         user_email: userEmail,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Received file name:", response.data.filename);
  //       setFileName(response.data.filename);
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred:", error);
  //     });
  // };
  // useEffect(() => {
  //   fetchFileName();
  //   console.log("Check Kr wa", fileName)
  // }, []);


  return (
    <>
      <ToastContainer />
      {/* <div className="container"> */}
      <div className="">
        {/* <Header /> */}
        <div className="d-flex justify-content-between py-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="font-weight-bold text-lg">Start Applying on Linkedin</h1>
          </div>
          <button
            onClick={() => navigate("../Dashboard")}
            type="submit"
            className="flex btn btn-primary border border-dark px-4 rounded-lg hover-bg-dark hover-text-white"
            style={{ width: 'fit-content', height: 'fit-content' }}
          >
            <IoIosArrowRoundBack size={25} className="mr-2" />
            Go Back
          </button>
        </div>

        <div className="mt-4 w-full h-auto">
          {/* <div className="h-auto w-50">
            <label className="text-secondary font-weight-bold ">
              Enter The Skill/Skills or Tags
            </label>
            <input
              type="text"
              className="form-control my-4 border border-secondary text-secondary text-sm rounded-lg"
              required
            />
            <label className="text-secondary ">
              For example You can enter Single Tag like Full Stack Developer or
              Multiple Tag like Java php etc.
            </label>
          </div> */}
          <div className="h-auto w-50">
            <label className="text-secondary fw-bold">
            Enter the Job Location (Optional)
            </label>
            <input
              type="text"
              className="form-control mt-2 mb-1 border border-secondary text-secondary text-sm rounded-lg"
            />
            <label className="text-secondary">
            For example, you can enter your job location: Atlanta, GA.
            </label>
          </div>
        </div>

        <div className="mt-4 w-50 h-auto">
          <label className="text-secondary fw-bold">
          Number of Jobs to Apply
          </label>
          <input
            type="text"
            className="form-control mt-2 mb-1 border border-secondary text-secondary text-sm rounded-lg"
            required
          />
          <label className="text-secondary">
          For example, enter the number of jobs to apply for, like 50 or 100.
          </label>
        </div>

        <div className="w-full h-auto mt-4">
          <div className="mt-4 w-50">
            <div className="d-flex justify-content-between">
              <label className=" text-secondary fw-bold mt-2 mb-1">Enter the Job You Want to Search</label>
            </div>
            <input
              type="text"
              className="form-control mt-2 mb-1 border border-secondary text-secondary text-sm rounded-lg"
              required
            />
          </div>
        </div>

        <div className="w-full h-auto mt-4">
          <div className="mt-4 w-50">
            <div className="d-flex justify-content-between">
              <label className=" text-secondary fw-bold mt-2 mb-1">Experience Level</label>
            </div>
            <div className="mt-2 w-100">
              <ExperienceDropdown />
            </div>

          </div>
        </div>

        <div className="mt-4 w-50 h-auto">
          <label className="text-secondary fw-bold">
            Linkedin Email
          </label>
          <input
            type="text"
            className="form-control mt-2 mb-1 border border-secondary text-secondary text-sm rounded-lg"
            required
          />
        </div>
        <div className="mt-4 w-50 h-auto">
          <label className="text-secondary fw-bold">
            Linkedin Password
          </label>
          <input
            type="password"
            className="form-control mt-2 mb-1 border border-secondary text-secondary text-sm rounded-lg"
            required
          />
        </div>

        <div className="mt-4 d-flex justify-content-start align-items-start">
          <button type="submit" className="btn btn-primary" style={{ width: 'fit-content', padding: '10px 20px' }} onClick={handleApplyJobs}>
            Start Applying
          </button>
        </div>
      </div>
    </>
  );
};

export default Linkedin;
