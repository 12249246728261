import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SignIn.scss";

const SignIn = () => {
  const [spinner, setSpinner] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token, navigate]);

  useEffect(() => {
    setEmailError("");
    setPasswordError("");
  }, []);

  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  useEffect(() => {
    setEmailError(
      email === ""
        ? "Required*"
        : isValidEmail(email)
        ? { data: "", status: true }
        : "Invalid email format"
    );
    setPasswordError(password ? { data: "", status: true } : "Required*");
  }, [email, password]);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleSignIn = async () => {
    setSpinner(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/Auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("email", email);
        localStorage.setItem("username", data.username);
        setSpinner(false);
        toast.success("Login Successful!", {
          position: "top-right",
          autoClose: 2000,
        });
        await delay(2000); // wait for 2 seconds before navigating
        navigate("/dashboard");
      } else {
        setSpinner(false);
        const errorData = await response.json();
        toast.error(errorData.detail[0].msg, {
          position: "top-right",
        });
      }
    } catch (error) {
      setSpinner(false);
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
      });
    }
  };

  if (token) {
    return null;
  }

  return (
    <>
      <div className="background-account">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <div className="signinPage">
          <div className="signin-content">
            <div className="signup-card">
              <div className="signupcard-content">
                <div className="main-card">
                  <div className="cardtitle">
                    <div className="leftside">
                      <h6>Log In</h6>
                    </div>
                  </div>
                  <div className="card-field">
                    <div className="firstfield">
                      <div className="label">
                        <label htmlFor="">Enter your email address</label>
                        <span className="span">
                          {emailError.status ? emailError.data : emailError}
                        </span>
                      </div>
                      <div className="inputfield">
                        <input
                          type="email"
                          placeholder="Email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="firstfield">
                      <div className="label">
                        <label htmlFor="">Enter your Password</label>
                        <span className="span">
                          {passwordError.status
                            ? passwordError.data
                            : passwordError}
                        </span>
                      </div>
                      <div className="inputfield">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div
                          className="eye-icon"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <Icon
                              icon="octicon:eye-24"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          ) : (
                            <Icon
                              icon="tabler:eye-off"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="forgetPassword">
                    <Link to="/forgetpassword">
                      <span>Forgot Password</span>
                    </Link>
                  </div>
                  <div className="annotherAccount">
                    <p>
                      Don’t have an Account?{" "}
                      <span style={{ cursor: "pointer" }}>
                        <Link to="/signup">Sign up</Link>
                      </span>
                    </p>
                  </div>

                  <div className="card-bottom">
                    <div className="signin-btn">
                      <button onClick={handleSignIn}>
                        
                        {spinner ? <span>Loading...</span> : "Log In"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
