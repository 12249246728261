import React from "react";
import "./testimonial.scss";
import sImg1 from "../../../assets/home/sliderImg1.png";
import sImg2 from "../../../assets/home/sliderImg2.png";
import sImg3 from "../../../assets/home/sliderImg3.png";
import sImg4 from "../../../assets/home/circle55.jpg";

import SliderCards from "./sliderCards";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Swiper from "swiper";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const Testimonial = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 787,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <>
      <div className="testimonial   mt-5">
        <div className="container">
          <h3>Testimonials</h3>
          <h2>What People Think About Us</h2>
          <div className="sliderCards">
            <Slider {...settings}>
              {sliderData.map((data, index) => (
                <SliderCards key={index} {...data} />
              ))}
            </Slider>
            {/* 
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper"
            >
              {sliderData.map((data, index) => (
                <SliderCards key={index} {...data} />
              ))}
            </Swiper> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;

const sliderData = [
  {
    id: 1,
    sliderImg: sImg1,
    name: "Sandy M",
    details:
    "I cannot express how grateful I am for Zenpply's game-changing platform. As a job seeker, the streamlined application process not only saved me precious time but also significantly increased my response rate from potential employers. The centralized management system is a lifesaver, keeping all my applications organized and easily accessible. Zenpply has truly transformed the way I approach job hunting. Highly recommended!",
  },

  {
    id: 2,
    sliderImg: sImg2,
    name: "Philip R",
    details:
    "Zenpply has become an indispensable tool in my job search journey. The intuitive AI-driven platform not only simplified the application process but also enhanced the visibility of my applications. The time I've saved by automating repetitive tasks allows me to focus on tailoring my applications and preparing for interviews. Thank you, Zenpply, for making the job search experience efficient and effective.",
  },

  {
    id: 3,
    sliderImg: sImg3,
    name: "Simon J",
    details:
    "I was initially skeptical about AI's role in job applications, but Zenpply proved me wrong. The platform's smart application submission feature significantly increased the relevance of my applications, leading to more interview opportunities. The user-friendly interface and centralized application management make Zenpply a must-have for any serious job seeker. It's a game-changer in the competitive world of job hunting!",
  },

  {
    id: 4,
    sliderImg: sImg4,
    name: "Katherine B",
    details:
    "Having used Zenpply's platform, I'm impressed by its seamless integration of job search tools. The personalized recommendations feature helped me discover opportunities I wouldn't have found otherwise. Moreover, the platform's intuitive interface made the application process a breeze. I highly recommend Zenpply to anyone looking to streamline their job search.",
  },

 
  // {
  //   id: 5,
  //   sliderImg: sImg1,
  //   name: "Robin",
  //   details:
  //     "I cannot express how grateful I am for Zenpply's game-changing platform. As a job seeker, the streamlined application process not only saved me precious time but also significantly increased my response rate from potential employers. The centralized management system is a lifesaver, keeping all my applications organized and easily accessible. Zenpply has truly transformed the way I approach job hunting. Highly recommended!",
  // },
];
