import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import "./pricing.scss";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const Pricing = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory



  const handlePayment = async (planName, price) => {
    const stripe = await loadStripe(
      "pk_test_51P8VaoEGthoWj151dGGYesBtg7QRzfSvRFSLU0pcGnOyJqfWqo3V7lcyA4dUhyrhw85A6B7hZxs2KRBVGvjMzWl3002E4GRFWd"
    );
    try {
      const userEmail = localStorage.getItem("email");
      if (!userEmail) {
        window.alert("Please log in first.");
        navigate("/signin"); // Use navigate instead of history.push
        return;
      }


      // Remove dollar sign and parse price string to float
      const parsedPrice = parseFloat(price.replace("$", ""));

      const payload = {
        email: userEmail,
        amount: parsedPrice * 4,
        planName: planName,
        base_url: window.location.origin,
      };
      console.log(payload)
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/create_payment_checkout_session`,
        payload
      );
      const session = response.data;
      console.log(session.sessionId, "session");
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });
      console.log("result", result);
      if (result.error) {
        console.log(result.error);
      }
    } catch (e) {
      console.log("Error Making a post request to Stripe Route", e);
    }
  };

  const pricingData = [
    {
      id: 1,
      label: "Starter Plan",
      price: "Free",
      btnText: "Sign Up, It's Free",
      Link: '/signup',
      jobInfo1: "Free 3 Jobs at Linkedin",
      content1: "Access to 3 job applications on LinkedIn.",
      content2:
        "Quick and easy setup to get you started on your job search journey.",
      content3: "Basic profile and resume management tools.",
      content4:
        "Limited access to job search filters and customization options",
      content5:
        "Ideal for those just beginning their job search or exploring new opportunities.",
      class: "free",
    },

    {
      id: 2,
      label: "Premium Plan",
      price: "$11.99",
      chat: "Per Week",
      btnText: "Buy now",
      Link: '/signup',
      jobInfo1: "1000 Jobs at Indeed/Linkedin",
      jobInfo2: "Billed Monthly",
      content1:
        "Enjoy unlimited access to 1000 job applications on Indeed and LinkedIn.",
      content2: "Enhanced profile visibility to recruiters and employers.",
      content3: "Priority application processing for faster response times.",
      content4: "Exclusive access to premium job search features and insights.",
      content5:
        "Personalised job recommendations based on your profile and preferences",
      content6: "Dedicated customer support for any assistance you may need.",
      content7:
        "Tailored for professionals seeking top-tier job opportunities and career advancement.",
      class: "premium",
    },

    {
      id: 3,
      label: "Basic Plan",
      price: "$7.99",
      chat: "Per Week",
      btnText: "Buy now",
      Link: '/signup',
      jobInfo1: "500 Jobs at Indeed/Linkedin",
      jobInfo2: "Billed Every Monthly",
      jobInfo4: "Lorem Ipsum",
      content1: "Unlock access to 500 job applications on Indeed and LinkedIn.",
      content2:
        "Comprehensive profile management tools for optimising your job search.",
      content3:
        "Advanced job search filters, including location, industry, and job type.",
      content4:
        "Resume optimization features to increase visibility to employers.",
      // content5:
      //   "Track application progress and receive notifications for new job listings.",
      class: "basic",
    },
  ];

  return (
    <>
      <div id="pricing" className="pricing container">
        <h1 className="h-center my-5">Pricing</h1>
        <div className="pricingCardWrapper">
          {pricingData.map((item, index) => (
            <div className={`cardss ${item.class}`} key={index}>
              <div className="card-detail">
                <h2>{item.label}</h2>
                <div className="chatContainer">
                  <h2 className="fw-normal">{item.price}</h2>
                  {item.chat && <p className="chatt">{item.chat}</p>}
                </div>

                {!window.localStorage.getItem("email") ?
                  <>
                    <Link to={item.Link} state={item.class == "free" ? {} : { label: item.label, price: item.price}}>
                      <button className="text-small fw-normal my-4">
                        {item.btnText}
                      </button>
                    </Link>

                  </>
                  :
                  <>
                    <button className="text-small fw-normal my-4" onClick={() => handlePayment(item.label, item.price)}>
                      {item.btnText}
                    </button>
                  </>
                }



                <ul className="text-small fw-normal">
                  <li>{item.jobInfo1}</li>
                  {item.jobInfo2 && <li className="pb-2">{item.jobInfo2}</li>}
                </ul>
                <div className="blueDiv my-3"></div>
                <div className="featureList">
                  <p>Key Features</p>
                  <ul className="text-small fw-normal ">
                    <li className="pb-2">{item.content1}</li>
                    <li className="pb-2">{item.content2}</li>
                    <li className="pb-2">{item.content3}</li>
                    <li className="pb-2">{item.content4}</li>
                    {item.content5 && (
                      <li className="pb-2">{item.content5}</li>
                    )}
                    {item.content6 && (
                      <li className="pb-2">{item.content6}</li>
                    )}
                    {item.content7 && (
                      <li className="pb-2">{item.content7}</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Pricing;
