import React from "react";
import "./subscribe.scss";

const Subscribe = () => {
  return (
    <>
      <div className="subscribeWrapper">
        <h1 className="fw-bold h-center mb-5">Subscribe Us</h1>

        <div className=" areaWrapper">
          <div className="leftArea">
            <h3 className="fw-bold whySubs">Why Subscribe Us</h3>
            <p className="text-small fw-normal">
              Subscribe to stay updated with the latest industry trends, job
              openings, and career advice. Be the first to know about our
              exclusive offers, webinars, and events. Don't miss out on valuable
              insights that can boost your career!
            </p>
          </div>
          <div className="rightArea">
            <h3 className="fw-bold whySubs text-skyBlue">Subscribe to our newsletter</h3>
            <p className="text-small fw-normal my-3">
            Enter your name and email below to subscribe to our newsletter.
            </p>

            <input type="text" placeholder="Enter Name" />
            <input type="email" placeholder="Enter Email" />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
