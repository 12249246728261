/*global chrome*/
import React, { useEffect, useState } from "react";
import "./Home.scss";
import Header from "../Components/Header";
import { CircularProgressbar } from "react-circular-progressbar";
import { FaLinkedin } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import indeed from "../../../assets/dashboard/indeed.png";
import linkedIn from "../../../assets/dashboard/linkedIn.png";
import JobsAppliedCard from "./jobsAppliedCard";
import axios from "axios";
import { MdOutlineArrowRight } from "react-icons/md";
import { SiIndeed } from "react-icons/si";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import extensionZip from "../../../assets/zennpply.zip"
import Modal from 'react-bootstrap/Modal';
import HowToUseModal from "./HowToUseModal";

const DashHome = ({ }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [fileName, setFileName] = useState("");
  const userEmail = localStorage.getItem('email');
  const [modalShow, setModalShow] = useState(false);

  console.log(result, "Result");

  const navigate = useNavigate();
  const carddetail = [
    {
      text1: "",
      text2: "",
      text3: "",
      text4: "",
      text5: "",
      text6: "",
      progressbar: 40,
    },
    {
      text1: "",
      text2: "",
      text3: "",
      text4: "",
      text5: "",
      text6: "",
      progressbar: 70,
    },
  ];
  // const card2detail = [
  //   {
  //     title: "LINKEDIN",
  //     text1: "type anything",
  //     text2: "type anything",
  //     text3: "type anything",
  //     text4: "type anything",
  //   },
  //   {
  //     title: "INDEED",
  //     text1: "type anything",
  //     text2: "type anything",
  //     text3: "type anything",
  //     text4: "type anything",
  //   },
  // ];



const checkExtension = (id, src, callback) => {
  let e = new Image()
  e.src = 'chrome-extension://'+ id +'/'+ src
  e.onload = () => callback(1);
  e.onerror = () => callback(0)
}

  const handleIndeedApply = async (isInstalled) => {
if (isInstalled == 1){
  toast.success("Extension successfully detected.")
  handleApplyJobs('Indeed');
}
else{
toast.error("Zenpply indeed extension is not detected. Please install it.")
const link = document.createElement('a');
    link.href = extensionZip;
    link.setAttribute(
      'download',
      `zenpplyExtension.zip`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}
    
      
  

  }

  const handleApplyJobs = async (btnValue) => {
    if (!fileName) {
      toast.error("Please upload your CV first!", { autoClose: 1500 });
      setTimeout(() => {
        navigate('/profile');
      }, 2000);
      return;
    } else {
      if (btnValue === 'Linkedin') {
        navigate("/applyLinkedin")
      } else if (btnValue === 'Indeed') {
        navigate("/applyindeed")
      } else {
        console.log("There is Some Error")
      }

    }
  };

  const fetchFileName = () => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}/get_filename`;

    axios
      .post(apiUrl, null, {
        params: {
          user_email: userEmail,
        },
      })
      .then((response) => {
        console.log("Received file name:", response.data.filename);
        setFileName(response.data.filename);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  useEffect(() => {
    fetchFileName();
    console.log("Check Kr wa", fileName)
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="Home-right-container-main">
        <div className="jobAppliedContainer">
          <JobsAppliedCard />
          <JobsAppliedCard />
        </div>

        <div className="platformContainer">
          <div className="linkedInCard">
            <img src={linkedIn} alt="" />
            <p className="applying">Start Applying via Linkedin</p>
            <button onClick={() => { handleApplyJobs('Linkedin') }}>
              Start Applying
            </button>
          </div>

          <div className="linkedInCard">
            <img src={indeed} alt="" />
            <p className="applying">Start Applying via Indeed</p>
            <button onClick={()=>{
            
            setModalShow(true);
            // checkExtension('kmmfiafpoklpmgnjmpjmmnnbblhibhnd', 'icons/placeholder.png', handleIndeedApply)
          }

            }
            // onClick={() => navigate("/applyindeed")}
            >Start Applying</button>
          </div>
        </div>
        <HowToUseModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />


        {/* <div className="Home-job-apply">
          {carddetail.map((card) => (
            <div className={`shadow-lg shadow-gray-300 ${`first-card`}`}>
              <div className="card-top">
                <div className="flex text-xl">
                  <h3 className="text-xl">Total Job Applied{card.text1}/</h3>
                  <h3>Total Job Left{card.text2}</h3>
                </div>
                <div className="card-middle">
                  <div className="flex text-xl">
                    <h2 className="text-black text-4xl">0{card.text4}</h2>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end">
                <div style={{ width: 100, height: 100 }}>
                  <CircularProgressbar
                    strokeWidth={4}
                    value={card.progressbar}
                    text={`${card.progressbar}%`}
                  />
                </div>
                <div className={`md:w-1/2 ${`card-bottom`}`}>
                  <p className="text-gray-400 font-bold ">
                    0% used, today - 0 job Applied{card.text5}
                  </p>
                  <p className="text-gray-400 font-bold">
                    Total session today - 0{card.text6}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div> */}
        {/* <div className={` ${`Home-middle-card`}`}>
          <div className={`shadow-lg shadow-gray-300 ${`First-card`}`}>
            <div className="card-icon">
              <FaLinkedin size={75} />
            </div>
            <div className="card-title">
              <h1 className="text-[20px] font-semibold">LINKEDIN</h1>
            </div>
            {/* <div className="card-data">
              <div className="flex">
                <MdOutlineArrowRight size={25} />
                <p className="text-gray-500 ">
                  740 million members woth over 55 millioon registered companies
                </p>
              </div>
              <div className="flex">
                <MdOutlineArrowRight size={25} />
                <p className="text-gray-500 ">
                  The Largest social network of professional networking and
                  career development
                </p>
              </div>
              <div className="flex">
                <MdOutlineArrowRight size={25} />
                <p className="text-gray-500 ">
                  Make sure you have a complete profile along with a resume
                  uplloaded before start applying.
                </p>
              </div>
              <div className="flex">
                <MdOutlineArrowRight size={25} />
                <p className="text-gray-500 ">
                  Make sure you are Logged in to Linkedin before you start
                  applying.
                </p>
              </div>
            </div> */}
        {/* <button
              type="submit"
              onClick={handleApplyJobs}
              className=""
            >
              Start Applying
            </button>
          </div>

          <div className={`shadow-lg shadow-gray-300 ${`First-card`}`}>
            <div className="card-icon">
              <SiIndeed size={75} />
            </div>
            <div className="card-title">
              <h1 className="text-[20px] font-semibold">INDEED</h1>
            </div> */}
        {/* <div className="card-data">
              <div className="flex">
                <MdOutlineArrowRight size={25} />
                <p className="text-gray-500 ">
                  740 million members woth over 55 millioon registered companies
                </p>
              </div>
              <div className="flex">
                <MdOutlineArrowRight size={25} />
                <p className="text-gray-500 ">
                  The Largest social network of professional networking and
                  career development
                </p>
              </div>
              <div className="flex">
                <MdOutlineArrowRight size={25} />
                <p className="text-gray-500 ">
                  Make sure you have a complete profile along with a resume
                  uplloaded before start applying.
                </p>
              </div>
              <div className="flex">
                <MdOutlineArrowRight size={25} />
                <p className="text-gray-500 ">
                  Make sure you are Logged in to Linkedin before you start
                  applying.
                </p>
              </div>
            </div> */}
        {/* <button type="submit" onClick={() => navigate("/Indeed")} className="">
              Start Applying
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default DashHome;
