import { FaQ } from "react-icons/fa6";
import "./App.css";
import Profilee from "./pages/Dashboard/Components/profilee";
import Dashboard from "./pages/Dashboard/Dashboard";
import FaqsPage from "./pages/Dashboard/FAQ/faqs";
import HomeDash from "./pages/Dashboard/Home/Home";
import JobsAppliedCard from "./pages/Dashboard/Home/jobsAppliedCard";
import Indeed from "./pages/Dashboard/Indeed/Indeed";
import JobSubmitted from "./pages/Dashboard/JobSubmitted/JobSubmitted";
import SubmittedPage from "./pages/Dashboard/JobSubmitted/Submitted";
import Linkedin from "./pages/Dashboard/Linkedin/Linkedin";
import EditProfile from "./pages/Dashboard/Profile/EditProfile";
import SignIn from "./pages/Dashboard/SignIn/SignIn";
import SignUp from "./pages/Dashboard/SignUp/SignUp";
import Editresume from "./pages/Dashboard/editresume/Editresume";
import Home from "./website/home";
import {
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import ResetPassword from "./pages/Dashboard/resetPassword/resetPassword";
import ConfigPassword from "./pages/Dashboard/resetPassword/configPassword";
import OTP from "./pages/Dashboard/resetPassword/otp";
import { useEffect, useState } from "react";
import PaymentSuccessful from "./pages/Dashboard/PaymentStatus/PaymentSuccessful";
import PaymentCancelled from "./pages/Dashboard/PaymentStatus/PaymentCancelled";
import UserProfile from "./pages/Dashboard/UserProfile/UserProfile";
import { PfpImgContext } from "./pages/Dashboard/context/pfpContext";
import { ToastContainer } from "react-toastify";
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('pk_test_51PAn4bRuaX0nwaO3fUoq5rdLZGN37pByvyXr3IZkGVG0uBYJTUpXQsVazOCACXVe9rT0IOKeISEIZNIcNF8vKIWs00QNbU450d');

const AppLaout = () => {
const [imageSrc, setImageSrc] = useState(false);
const contextProps = {
  imageSrc: imageSrc,
  setImageSrc: setImageSrc
}
  return (
    <>
      <ToastContainer />
      {/* <Elements stripe={stripePromise}> */}
      <PfpImgContext.Provider value={contextProps}>
      <RouterProvider router={appRouter} />
      {/* </Elements> */}
      </PfpImgContext.Provider>
    </>
  );
};
const AppNew = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!token) {
      console.log(token);
      navigate("/signin");
    }
  }, []);

  if (!token) {
    return null
  } else {
    return (
      <>
        <Outlet />
      </>
    );
  }

};



const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/forgetpassword",
    element: <ResetPassword />,
  },
  {
    path: "/otp",
    element: <OTP />,
  },
  {
    path: "/configPassword",
    element: <ConfigPassword />,
  },
  {
    path: "/paymentSuccessful",
    element: <PaymentSuccessful />,
  },
  {
    path: "/paymentCancelled",
    element: <PaymentCancelled />,
  },
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard",
        element: <HomeDash />,
      },
      {
        path: "/profile",
        element: <Profilee />,
        children: [
          {
            path: "",
            element: <EditProfile />,
          },
          {
            path: "userinfo",
            element: <UserProfile />,
          }
        ]
      },
      {
        path: "/applyindeed",
        element: <Indeed />,
      },
      {
        path: "/applyLinkedin",
        element: <Linkedin />,
      },
      {
        path: "/submited",
        element: <SubmittedPage />,
      },
      {
        path: "/faqs",
        element: <FaqsPage />,
      },
    ],
  },
]);
// function App() {
//   return (
//     <>
//       <div className="App">
//         {/* <Dashboard /> */}
//         {/* <SignIn /> */}
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/signin" element={<SignIn />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/Linkedin" element={<Linkedin />} />
//           <Route path="/submited" element={<SubmittedPage />} />
//           <Route path="/faqs" element={<FaqsPage />} />
//           <Route path="/Indeed" element={<Indeed />} />
//           <Route path="/profile" element={<Profilee />} />
//         </Routes>
//       </div>

//       <RouterProvider router={appRouter} />
//     </>
//   );
// }

export default AppLaout;
