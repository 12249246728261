import React, { useContext, useEffect, useState } from "react";
import "./UserProfile.scss";
import { FaCamera } from "react-icons/fa";
import axios from 'axios';
import  placeholderImf from "../../../assets/dashboard/pfpPlaceholder.jpg"
import { PfpImgContext } from "../context/pfpContext";

const UserProfile = () => {
    const [username, setUsername] = useState(localStorage.getItem("username") || "John Doe");
    const [email, setEmail] = useState(localStorage.getItem("email") || "john@example.com");
    const [isEditing, setIsEditing] = useState(false);
    const [tempUsername, setTempUsername] = useState(username);
    const [tempEmail, setTempEmail] = useState(email);
    // const [imageSrc, setImageSrc] = useState(localStorage.getItem("imageSrc") || "../../../../user.png");
    // const [imageSrc, setImageSrc] = useState(false); 
const {imageSrc, setImageSrc} = useContext(PfpImgContext);

    useEffect(() => {
        const fetchUserProfilePicture = async () => {
            const userEmail = localStorage.getItem('email');
            console.log(userEmail); // Log the userEmail

            if (userEmail) {
                try {
                    const requestBody = {
                        user_email: userEmail
                    };

                    await axios.post(
                        `${process.env.REACT_APP_BASEURL}/get_user_profile_picture`,
                        requestBody,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }
                    ).then((res) => {
                        const data = res.data.picture_data;
                        console.log("User Profile Data:", data);
                        console.log("User picture_name:", res.data.picture_name);
                        setImageSrc(data);
                        console.log("User Profile Data:", imageSrc);

                    }).catch((err) => {
                        console.log(err)
                    })

                } catch (error) {
                    console.error('Error fetching user profile picture:', error);
                }
            }
        };

        fetchUserProfilePicture();
    }, []);


    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        setUsername(tempUsername);
        setEmail(tempEmail);
        setIsEditing(false);
        localStorage.setItem("username", tempUsername);
        localStorage.setItem("email", tempEmail);
        // if (imageSrc !== "../../../../user.png") {
        //     localStorage.setItem("imageSrc", imageSrc);
        // }
    };

    const handleUserImg = (e) => {
        const file = e.target.files[0];
        console.log("uploadFile", file)
        if (file) {
            if (file.size <= 2097152) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const pictureData = reader.result.split(',')[1];
                    const pictureName = file.name;
                    const user_email = localStorage.getItem('email');

                    const requestBody = {
                        user_email,
                        picture_name: pictureName,
                        picture_data: pictureData,
                    };
                    console.log(requestBody, 'First')
                    fetch(`${process.env.REACT_APP_BASEURL}/user_profile_picture`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(requestBody)
                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log("hyhy",reader.result.split(',')[1]);
                            setImageSrc(reader.result.split(',')[1]);
                            // localStorage.setItem("imageSrc", reader.result);
                        })
                        .catch(error => {
                            console.error('Error:', error);
                            alert("An error occurred while uploading the profile picture.");
                        });
                }
                reader.readAsDataURL(file);
            } else {
                alert("Please select an image file with size less than 2MB.");
            }
        }
    };




    return (
        <div className="UserProfile-main-container">
            <div className="UserProfile-inner-container">
                <div className="UserProfile-img-box">
                    <img src={imageSrc ? `data:image/jpeg;base64,${imageSrc}` : placeholderImf} alt="" />
                    <label htmlFor="userImageInput" className="Overlay">
                        <FaCamera />
                    </label>
                    <input
                        id="userImageInput"
                        type="file"
                        accept="image/*"
                        onChange={handleUserImg}
                        style={{ display: "none" }}
                    />
                </div>
                {isEditing ? (
                    <>
                        <input
                            type="text"
                            value={tempUsername}
                            onChange={(e) => setTempUsername(e.target.value)}
                            placeholder="Enter username"
                            className="user-input"
                        />
                        <input
                            type="email"
                            value={tempEmail}
                            onChange={(e) => setTempEmail(e.target.value)}
                            placeholder="Enter email"
                            className="user-input"
                        />
                        <button className="edit-btn" onClick={handleSave}>
                            Save
                        </button>
                    </>
                ) : (
                    <>
                        <h6>
                            <span>Username:</span> {username}
                        </h6>
                        <h6>
                            <span>Email:</span> {email}
                        </h6>
                        {/* <button className="edit-btn" onClick={handleEdit}>
                            Edit
                        </button> */}
                    </>
                )}
            </div>
        </div>
    );
};

export default UserProfile;
