import React from "react";
import "./keyFeatures.scss";

const KeyFeatures = () => {
  const featuresData = [
    {
      id: 1,
      title: "Centralized Application Management",
      description:
        "Say goodbye to scattered applications. Zenpply brings all your job applications into one centralized platform for easy management.",
    },
    {
      id: 2,
      title: "Smart Application Submission",
      description:
        "Our AI-driven system intelligently submits your applications to the right opportunities, increasing your chances of success.",
    },

    {
      id: 3,
      title: "Time-Efficient Process",
      description:
        "Save valuable time by eliminating repetitive tasks. Zenpply automates the tedious aspects of the job application process, allowing you to invest your time where it matters.",
    },

    {
      id: 4,
      title: "Enhanced Job Search Visibility",
      description:
        "Stand out in the crowded job market. Zenpply ensures your applications receive the attention they deserve, enhancing your overall job search visibility.",
    },
  ];
  return (
    <>
      <div id="keyfeature" className="container keyFeatures">
        <h1 className="h-center mb-5">Key Features</h1>
        <div className="key-card-main d-flex flex-column">
          {featuresData.map((key) => (
            <div className="col-lg-12 col-md-12 col-sm-12 key-card " key={key}>
              <div className="keycard-top"></div>
              <div className="keycard-bottom ">
                <h3 className="text-skyBlue">{key.title}</h3>
                <p className="text-small fw-normal py-4">{key.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default KeyFeatures;
