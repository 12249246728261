import React from "react";
import "./howToUse.scss";
import descBottomGlow from "../../../assets/home/botmGlow.webp";
import desImg1 from "../../../assets/videos/Sequence.mp4";
import desImg2 from "../../../assets/home/Screenshot (8).png";
import desImg3 from "../../../assets/home/descImg3.png";
import desImg4 from "../../../assets/home/descImg4.png";
import desImg5 from "../../../assets/home/descImg5.png";
import descTopGlow from "../../../assets/home/descTopGlow.webp";
import DescriptionCard from "./descriptionCard";

const HowToUse = () => {
  return (
    <>
      <div id="howtouse" className="container howToUse">
        <img className="descTopGlow" src={descTopGlow} alt="" />
        <img className="descBottomGlow" src={descBottomGlow} alt="" />

        <h1 className="text-center pb-3">How to use Zenpply</h1>
        <h3 className="text-center">
          A Little Description About How To Apply For Job{" "}
        </h3>
        <div className="cardssContainer">
          {jobLinkData.map((data, index) => (
            <DescriptionCard key={index} {...data} />
          ))}
        </div>
      </div>
    </>
  );
};
const jobLinkData = [
  {
    id: 1,
    vid: true,
    cardImg: desImg1,
    title: "Install Zenpply’s extension for your browser.",
    details:
      "Combining the power of our cutting-edge AI platform with the convenience of browser extension technology, Zenpply's extension is the perfect complement to our innovative solution. By installing Zenpply's extension for your browser, you unlock a gateway to effortless job application submission across numerous online job portals. Seamlessly integrate our platform into your browsing experience and enjoy a streamlined, time-saving approach to landing your dream job. With Zenpply, the future of job hunting is at your fingertips.",
    btnDownload: "Download",
    cardSwapping: " ",
  },

  {
    id: 2,
    cardImg: desImg2,
    title: "Create your Profile and Upload resume",
    details: "Unlock the full potential of Zenpply's platform today:",
    content1:
      "Take the first step towards a more efficient job application process.",
    content2:
      "Provide key information about your skills, experience, and career goals.",
    content3:
      "Craft a compelling profile easily with our user-friendly interface ",
    content4:
      "Upload your resume to allow our AI-powered system to tailor your applications and maximize your chances of success.",
    cardSwapping: "swapping",
  },

  {
    id: 3,
    cardImg: desImg3,
    title: "Set search key words and answers",
    details:
      "Optimize your job search with Zenpply:",
    content1:
      "Set personalized search keywords and answers.",
    content2: "Define specific keywords related to your desired job roles, industries, skills, and locations.",
    content3: "Enable our AI system to accurately match your profile with relevant job opportunities.",
    content4: "Take control of your job search journey and connect with your ideal job opportunities effortlessly.",

    cardSwapping: " ",
  },

  {
    id: 4,
    cardImg: desImg4,
    title: "Identify Job Opportunity",
    details:
      "Zepply’s AI identifies the top job opportunities on the market that are best suited for you and automatically send out job applications",
    cardSwapping: "swapping",
  },

  {
    id: 5,
    cardImg: desImg5,
    title: "Apply for jobs faster",
    details:
      "With Zenpply's innovative platform, applying for jobs has never been faster or easier. Our streamlined process enables you to submit applications with just a few clicks, saving you valuable time and effort. By integrating your profile and resume seamlessly with our AI-powered system, Zenpply ensures that your applications are tailored to each job listing, maximizing your chances of success. Say goodbye to the tedious task of manually filling out multiple application forms – with Zenpply, you can apply for jobs faster and more efficiently than ever before. Take the first step towards your next career opportunity today and experience the convenience of Zenpply's job application process.",
    cardSwapping: " ",
  },
];
export default HowToUse;
