import boyImg from "../../../assets/dashboard/pfpPlaceholder.jpg";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./Header.scss";
import downarow from "../../../assets/dashboard/downArrow.png";
import { CiLogout } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { PfpImgContext } from "../context/pfpContext";

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);
  const token = localStorage.getItem("accessToken");
  const username = localStorage.getItem("username");
  const userImg = localStorage.getItem("imageSrc") || '../../../../user.png';
  // const [imageSrc, setImageSrc] = useState("" || '../../../../user.png');
const {imageSrc, setImageSrc} = useContext(PfpImgContext);

  const openmodal = () => {
    setOpen(!open);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlelogOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    navigate("/signin");
  }



  useEffect(() => {
    const fetchUserProfilePicture = async () => {
      const userEmail = localStorage.getItem('email');
      console.log(userEmail); // Log the userEmail

      if (userEmail) {
        try {
          const requestBody = {
            user_email: userEmail
          };

          await axios.post(
            `${process.env.REACT_APP_BASEURL}/get_user_profile_picture`,
            requestBody,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          ).then((res) => {
            console.log("Hehe", res.data);
            const data = res.data.picture_data;
            console.log("User Profile Data:", data);
            console.log("User picture_name:", res.data.picture_name);
            setImageSrc(data);
            console.log("User Profile Data:", imageSrc);

          }).catch((err) => {
            setImageSrc(false)
            console.log(err)
          })

        } catch (error) {
          console.error('Error fetching user profile picture:', error);
        }
      }
    };

    fetchUserProfilePicture();
  }, []);

  return (
    // <div className="flex flex-col w-auto ">
    //   <div className=" flex justify-evenly items-center  p-4">
    //     <form className="w-25 mx-auto">
    //       <label
    //         className="mb-2 text-sm font-medium text-gray-900 sr-only "
    //       >
    //         Search
    //       </label>
    //       <div className="relative">
    //         <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
    //           <svg
    //             className="w-4 h-4 text-gray-500 "
    //             aria-hidden="true"
    //             xmlns="http://www.w3.org/2000/svg"
    //             fill="none"
    //             viewBox="0 0 20 20"
    //           >
    //             <path
    //               stroke="currentColor"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //               stroke-width="2"
    //               d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
    //             />
    //           </svg>
    //         </div>
    //         <input
    //           type="search"
    //           id="default-search"
    //           className="block w-full px-4 py-2 ps-10 text-sm text-gray-900 bg-gray-300 rounded-2xl"
    //           placeholder="Search ..."
    //           required
    //         />
    //         <button
    //           type="submit"
    //           className="text-white absolute  end-1 bottom-1 bg-[#4d7db6] hover:bg-[#1e4081] font-medium rounded-2xl text-sm px-6 py-1 "
    //         >
    //           Search
    //         </button>
    //       </div>
    //     </form>
    //     <div className="flex flex-col rounded-lg">
    //       <div
    //         ref={modalRef}
    //         tabIndex={0}
    //         onClick={openmodal}
    //         className="flex items-center "
    //       >
    //         <img src={"/user.png"} width="50px" height="50px" />
    //         <FaAngleDown size={25} />
    //       </div>
    //       {open && (
    //         <div
    //           tabIndex={0}
    //           className="rounded-lg space-x-3 right-0 py-3 w-28 px-1 flex mt-16 absolute z-20 border-green-600 border  bg-gray-300"
    //         >
    //           <CiLogout size={25} />
    //           <h1>Log Out</h1>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="headerWrapper">
        <div className="leftContent">
          <img src="" alt="" />
          <p>Hi {username},</p>
          <h2>Welcome to Zenpply!</h2>
        </div>
        <div className="rightContent">
          <input type="search" name="" id="" placeholder="Search" />
          <div className="profile-icon">
            <div>
              <img className="boyImg" src={ imageSrc ? `data:image/jpeg;base64,${imageSrc}` : `${boyImg}`} alt="" />
              <img className="arrowClass" src={downarow} alt="" />
            </div>
            <ul className="drop-down">
              <div className="border rounded-4 overflow-hidden" style={{zIndex: 99}}>
                <NavLink to="profile/userinfo" className="nav-link">Edit Profile</NavLink>
                <li className="nav-link" onClick={() => { handlelogOut() }}>Log Out</li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
