import React from 'react'
import './PaymentStatus.scss'
import { Icon } from '@iconify/react'
const PaymentSuccessful = () => {
    return (
        <div className='PaymentSuccessful-main-container'>
            <Icon className='iconPayment' icon="teenyicons:tick-circle-solid"  />
            <h1>Payment Successful</h1>
        </div>
    )
}

export default PaymentSuccessful