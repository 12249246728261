import React from "react";
import "./hero.scss";
import blueGlow from "../../../assets/home/blueGlow.webp";
import heroSecImg from "../../../assets/home/hero-right-img.png";
import heroVideo from "../../../assets/videos/Merged Sequence.mp4";

const Hero = () => {
  return (
    <>
      <div id="hero" className="heroWrapper">
        <img className="blueGlow img-fluid" src={blueGlow} alt="" />

        <div className="leftArea">
          <h2 className="">Welcome to Zenpply</h2>
          <h1>Transforming Your Job Search Experience!</h1>
          <button className="btn-primary-blue">Explore</button>
        </div>

        <div className="rightArea">
          <video loop autoPlay playsInline muted>
            <source src={heroVideo} type="video/mp4" />
          </video>
        </div>

      </div>

      <div className="container">
        <div className="ourSol  ">
          <h3 className="text-skyBlue">Our Solution</h3>
          <p className="text-small fw-normal">
            Zenpply is developing an innovative AI-powered platform designed to
            revolutionize the job application process. Our solution will
            streamline and automate the submission of applications across
            various online job portals, providing you with a seamless and
            time-efficient experience. With Zenpply, you can focus on what
            matters most – showcasing your skills and securing the opportunities
            you deserve.
          </p>
        </div>
      </div>
    </>
  );
};

export default Hero;
