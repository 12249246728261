import React from 'react'
import './PaymentStatus.scss'
import { Icon } from '@iconify/react'

const PaymentCancelled = () => {
    return (
        <div className='PaymentSuccessful-main-container'>
            <Icon className='iconPayment-cancel' icon="gridicons:cross-circle" />
            <h1 className='cancel-h1'>Payment Cancelled</h1>
        </div>
    )
}

export default PaymentCancelled