import React from "react";
import "./jobSearch.scss";
import { Link } from "react-router-dom";

const JobSearch = () => {
  return (
    <>
      <div className="jobSearch">
        <h1 className="text-white h-center">
          Try our AI powered Job application automation tool to automate job
          search
        </h1>
        <Link to='/signup' className="getStarted">Get Started</Link>
      </div>
    </>
  );
};

export default JobSearch;
