import React from "react";
import "./jobsAppliedCard.scss";
import blueLine from "../../../assets/dashboard/blueLine.png";
import { Progress } from "antd";

const JobsAppliedCard = () => {
  return (
    <div className="jobAppliedCard">
      <div className="topArea">
        <h4>Total Jobs Applied/Total Jobs Left</h4>
        <h1>0</h1>
        <img className="blueLine" src={blueLine} alt="" />
      </div>
      <div className="bottomArea">
        <Progress
          type="circle"
          percent={65}
          strokeColor={"bluegreen"}
          strokeWidth={15}
        />
        <p>0% used, today - 0 Job Applied Total Session Today - 0</p>
      </div>
    </div>
  );
};

export default JobsAppliedCard;
