import React, { useEffect, useState } from 'react';

const ExperienceDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [checkedItems, setCheckedItems] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
    });
const [selected, setSelected] = useState([])
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [id]: checked,
    }));
    if (checked){

        setSelected(prev=>([...prev, event.target.name]))
    }
    else{
        setSelected(prev =>{
            return prev.filter((value)=>value != event.target.name)
        })
    }
    };

    useEffect(()=>{
        console.log("dd", checkedItems);
        console.log("dd", selected);
    },[checkedItems])
    return (
        <div className="dropdown mt-2 w-100">
            <button
                className="btn btn-secondary dropdown-toggle d-flex justify-content-between align-items-center"
                type="button"
                style={{ backgroundColor: '#fff', color: '#000', width: '100%', textAlign: 'left' }}
                onClick={toggleDropdown}
            >
                {selected.length > 0 ? selected.join(", ") : "Select Experience Level"}
            </button>
            {isOpen && (
                <div className="dropdown-menu show p-3">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox1"
                            checked={checkedItems.checkbox1}
                            onChange={handleCheckboxChange}
                            name="Internship"
                        />
                        <label className="form-check-label" htmlFor="checkbox1">
                            Internship
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox2"
                            checked={checkedItems.checkbox2}
                            onChange={handleCheckboxChange}
                            name="Entry level"
                        />
                        <label className="form-check-label" htmlFor="checkbox2">
                            Entry level
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox3"
                            checked={checkedItems.checkbox3}
                            onChange={handleCheckboxChange}
                            name="Associate"
                        />
                        <label className="form-check-label" htmlFor="checkbox3">
                            Associate
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox4"
                            checked={checkedItems.checkbox4}
                            onChange={handleCheckboxChange}
                            name="Mid-Senior level"
                        />
                        <label className="form-check-label" htmlFor="checkbox4">
                            Mid-Senior level
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox5"
                            checked={checkedItems.checkbox5}
                            onChange={handleCheckboxChange}
                            name="Director"
                        />
                        <label className="form-check-label" htmlFor="checkbox5">
                            Director
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox6"
                            checked={checkedItems.checkbox6}
                            onChange={handleCheckboxChange}
                            name="Executive"
                        />
                        <label className="form-check-label" htmlFor="checkbox6">
                            Executive
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExperienceDropdown;
