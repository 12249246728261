import Header from "../Components/Header";
import Editresume from "../editresume/Editresume";
import Modal from "./Components/Modal";
import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./EditProfile.scss";
import { IoIosArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";

const questions = [
  {
    category: "Work Authorization",
    question: [
      "Are you legally authorized to work in the US?",
      "Either now or in the future will you require work authorization to work in the US?",
    ],
    answers: ["Yes", "No"],
  },
  // {
  //   category: "In Office",
  //   question: ["Are you willing to work in Austin Texas?"],
  //   answers: ["Yes", "Yes, but would need to be relocated"],
  // },
];
const EditProfile = () => {
  const [openmodal, setOpenmodal] = useState(false);
  const [show, setshow] = useState(false);
  const [cv, setCv] = useState();
  const [pdfname, setpdfname] = useState();
  const [fileName, setFileName] = useState("");
  const [selectedfileName, setSelectedFileName] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const [UploadSuccess, setUploadSuccess] = useState(false);

  const [currentSalary, setCurrentSalary] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [showexpectedSalary, setShowExpectedSalary] = useState("");
  const [showcurrentSalary, setShowCurrentSalary] = useState("");
  const userEmail = localStorage.getItem("email");
  const [profileQuestions, setProfileQuestions] = useState({});

  const handleQuestionsChange = (e, answer) => {
    console.log("Questions Changed", { [e.target.name]: answer });
    setProfileQuestions((prev) => ({ ...prev, [e.target.name]: answer }));
  };

  const handlemodal = () => {
    setOpenmodal((prev) => !prev);
  };

  useEffect(() => {
    console.log("CV", cv);
  }, [cv]);

  const handleUpload = () => {
    console.log(cv);
    if (cv && currentSalary && expectedSalary) {
      const formData = new FormData();
      formData.append("document", cv);
      formData.append("user_email", userEmail);
      formData.append("current_salary", currentSalary);
      formData.append("expected_salary", expectedSalary);
      axios
        .post(`${process.env.REACT_APP_BASEURL}/extract_text_data`, formData)
        .then(async (response) => {
          // setUploadSuccess(true);
          console.log(response.data);
          const questionsUploadRequest = await axios.post(
            "https://oyster-app-rbmlt.ondigitalocean.app/work_authorization_questions",
            {
              questions: { ...profileQuestions },
              user_email: userEmail,
            }
          );
          if (questionsUploadRequest?.status == "success") {
            setUploadSuccess(true);
          } else {
            toast.success(
              "Profile Data Updated but profile questions couldn't be updated."
            );
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      console.log("Please upload CV and fill in all fields.");
    }
  };

  const fetchFileName = () => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}/get_filename`;

    axios
      .post(apiUrl, null, {
        params: {
          user_email: userEmail,
        },
      })
      .then((response) => {
        console.log("Received file name:", response.data.filename);
        setFileName(response.data.filename);
        setCurrentSalary(response.data.current_salary);
        setExpectedSalary(response.data.expected_salary);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  };

  const fetchProfileQuestions = async () => {
    try {
      const result = await axios.get(
        `https://oyster-app-rbmlt.ondigitalocean.app/get_work_authorization_questions?user_email=${userEmail}`
      );
      console.log("Result", result);
      if (result.data.work_authorization_questions) {
        setProfileQuestions(result.data.work_authorization_questions);
      }
    } catch (e) {
      console.log("Error fetching profile questions");
    }
  };

  useEffect(() => {
    fetchFileName();
    fetchProfileQuestions();
  }, []);

  // Function to handle file selection
  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFileSelected(true);
      setCv(selectedFile);
      setSelectedFileName(selectedFile.name);
    } else {
      console.log("Please upload a valid DOCX or PDF file.");
    }
  };

  return (
    <div className="EditProfile-main-container">
      {!UploadSuccess ? (
        <div className="EditProfile-inner-container">
          <div className="EditProfile-input-box">
            <div>
              <label>Current Salary</label>
              <input
                type="number"
                value={currentSalary}
                onChange={(e) => setCurrentSalary(e.target.value)}
              />
            </div>
            <div>
              <label>Expected Salary</label>
              <input
                type="number"
                value={expectedSalary}
                onChange={(e) => setExpectedSalary(e.target.value)}
              />
            </div>
          </div>
          <div className="EditProfile-upload-box">
            <label>
              Upload Your Resume
              <input
                type="file"
                accept=".docx,.pdf"
                onChange={handleFileSelect}
                style={{ display: "none" }}
              />
            </label>
          </div>
          <div className="profileQuestions">
            <p className="questionsDisclaimer">
              Some employers may ask demographic question.
            </p>
            <div className="questionsCategory">
              {questions &&
                questions.map((question) => (
                  <div>
                    <h1>{question.category}</h1>
                    <div className="questions">
                      {question?.question.map((singleQuestion) => (
                        <div>
                          <p>{singleQuestion}</p>
                          <div className="answers">
                            {question?.answers?.map((answer) => (
                              <div className="option">
                                <input
                                  type="radio"
                                  name={singleQuestion}
                                  onChange={(e) =>
                                    handleQuestionsChange(e, answer)
                                  }
                                  checked={
                                    profileQuestions[singleQuestion] == answer
                                      ? true
                                      : false
                                  }
                                ></input>
                                <label>{answer}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {fileSelected && (
              <>
                <div className="text-center">
                  <span style={{ fontWeight: "500", color: "#0748A6" }}>
                    Selected File:
                  </span>{" "}
                  {selectedfileName}
                </div>
                <button className="uploadBtn mt-3" onClick={handleUpload}>
                  Upload
                </button>
              </>
            )}
          </div>
          {/* {fileName && (
            <div className="text-center d-flex" style={{ gap: '20px', backgroundColor: '#F4F4F4', padding: '8px 20px 0px 20px', borderRadius: '10px' }}>
              <div className="d-flex pt-2 gap-1">
                <span style={{ fontWeight: "500", color: "#0748A6" }}>
                  Previous File:
                </span>{" "}
                <p style={{fontWeight:'600'}}>
                {fileName}
              </p>
              </div>
              <div className="d-flex pt-2 gap-1">
                <span style={{ fontWeight: "500", color: "#0748A6" }}>
                  Current Salary:
                </span>{" "}
                <p style={{fontWeight:'600'}}>
                {showcurrentSalary}
              </p>
              </div>
              <div className="d-flex pt-2 gap-1">
                <span style={{ fontWeight: "500", color: "#0748A6" }}>
                  Expected Salary:
                </span>{" "}
                <p style={{fontWeight:'600'}}>
                {showexpectedSalary}
              </p>
              </div>
            </div>
          )} */}
        </div>
      ) : (
        <div className="PaymentSuccessful-main-container">
          <Icon className="iconPayment" icon="teenyicons:tick-circle-solid" />
          <h1>Upload Successful</h1>
        </div>
      )}
    </div>
  );
};

export default EditProfile;

// import Header from "../Components/Header";
// import Editresume from "../editresume/Editresume";
// import Modal from "./Components/Modal";
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import './EditProfile.scss';
// import { IoIosArrowRoundBack } from "react-icons/io";

// const EditProfile = () => {
//   const [openmodal, setOpenmodal] = useState(false);
//   const [show, setshow] = useState(false);
//   const [cv, setCv] = useState();
//   const [pdfname, setpdfname] = useState();
//   const [fileName, setFileName] = useState("");

//   const handlemodal = () => {
//     setOpenmodal((prev) => !prev);
//   };

//   useEffect(() => {
//     console.log(cv);
//   }, [cv]);

//   const handleUpload = () => {
//     const userId = 123; // User ID as an integer

//     console.log(cv);
//     if (cv) {
//       // Create FormData object
//       const formData = new FormData();
//       formData.append("document", cv);
//       formData.append("user_id", userId); // Append user ID to FormData

//       // Example: Replace 'your-api-endpoint' with your actual API endpoint
//       axios
//         .post(
//           "https://ai-joblink-pro-python-1.onrender.com/extract_text_data",
//           formData
//         )
//         .then((response) => {
//           console.log(response.data);
//         })
//         .catch((error) => {
//           console.error("Error: ", error);
//         });
//     } else {
//       console.log("Please upload cv");
//     }
//   };

//   const fetchFileName = () => {
//     const apiUrl = "https://ai-joblink-pro-python-1.onrender.com/get_filename";

//     // User ID to send as a query parameter
//     const userId = 123;

//     // Make a POST request using Axios with the user ID as a query parameter
//     axios
//       .post(apiUrl, null, {
//         params: {
//           user_id: userId,
//         },
//       })
//       .then((response) => {
//         // Log the received file name in the console
//         console.log("Received file name:", response.data.filename);
//         setFileName(response.data.filename);
//       })
//       .catch((error) => {
//         // Log any errors that occur
//         console.error("Error occurred:", error);
//       });
//   };

//   useEffect(() => {
//     fetchFileName();
//   }, [fileName]);

//   return (
//     <div className="EditProfile-main-container">

//       <div
//         className="EditProfile-inner-container"
//       >
//         <div className="EditProfile-upload-box">
//           <label>
//             Upload Your Resume
//             <input type="file" onChange={(e) => setCv(e.target.files[0])} style={{ display: 'none' }} />
//           </label>
//         </div>
//         {/* <button
//           onClick={fetchFileName}
//           className=" text-4xl font-bold p-2 w-1/4 border border-black rounded-lg hover:bg-black hover:text-white mb-6 hover:scale-110 ease-in duration-300"
//         >
//           Get File Name
//         </button> */}
//         <div >
//           <button onClick={handleUpload}>Upload</button>
//           <div className="text-center">File: {fileName}</div>
//         </div>
//       </div>

//       {/* {show ? (
//         <Editresume pdfname={pdfname}  setshow = {setshow} setOpenmodal={setOpenmodal} />
//       ) : (
//         <div className="h-screen w-screen">

//           <div className="bg-blue-200 mt-10 h-1/3 p-4">
//             <div className="flex justify-end space-x-6 mx-4">
//               <button
//                 onClick={handlemodal}
//                 type="submit"
//                 className="border border-black py-2 px-4 rounded-md hover:bg-black hover:text-white"
//               >
//                 Upload Your Resume/CV
//               </button>
//               <button
//                 type="submit"
//                 className="flex border border-black py-2 px-4 rounded-lg hover:bg-black hover:text-white"
//               >
//                 <IoIosArrowRoundBack size={25} className="mr-2" />
//                 Go Back
//               </button>
//             </div>
//             <div className="flex justify-center items-center h-full ">
//               <button
//                 onClick={handlemodal}
//                 className=" text-4xl font-bold p-4 w-1/4 border border-black rounded-lg hover:bg-black hover:text-white mb-6 hover:scale-110 ease-in duration-300"
//               >
//                 Upload Your Resume
//               </button>
//             </div>
//           </div>
//           <div className="flex justify-center items-center w-1/2">
//             <Modal
//               setshow={setshow}
//               openmodal={openmodal}
//               setOpenmodal={setOpenmodal}
//               setpdfname = {setpdfname}
//               pdfname = {pdfname}
//             />
//           </div>
//         </div>
//       )} */}
//     </div>
//   );
// };

// export default EditProfile;
