import Header from "../Components/Header";
import SideBar from "../sidebar";
import JobSubmitted from "./JobSubmitted";
import React from "react";

function SubmittedPage() {
  return (
    <div className="main-dashboard-container">
 
 
   
        <JobSubmitted />
      
    </div>
  );
}

export default SubmittedPage;
