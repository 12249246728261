import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <h3>AI Job Link Pro</h3>
      <p className="text-large fw-semibold">
        © 2024 Copyright zenpply.com
      </p>
    </div>
  );
};

export default Footer;
