import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.scss";
import Header from "./Components/Header";
import Home from "./Home/Home";
import SideBar from "./sidebar";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  console.log()

  return (
    <>
      <div className="main-dashboard-container">
        <SideBar />
        <div className="dashboard-right-container">
          {/* <Home /> */}
          <Header />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Dashboard;