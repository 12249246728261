import React from "react";
import "./profilee.scss";
import Home from "../Home/Home";
import EditProfile from "../Profile/EditProfile";
import SideBar from "../sidebar";
import Header from "./Header";
import { NavLink, Outlet, useLocation } from "react-router-dom";

const Profilee = () => {
  const location = useLocation();
  return (
    <div>
      <div className="profile-tabs-container d-flex">
        <NavLink to="/profile" activeClassname="active" className={`profile-tab-box ${location === '/profile' ? 'activenEW' : ''}`}>Resume</NavLink>
        <NavLink to="/profile/userinfo" activeClassname="active" className={`profile-tab-box ${location === '/profile/userinfo' ? 'active' : ''}`}>UserInfo</NavLink>
      </div>
      <Outlet />
      {/* <EditProfile /> */}
    </div>
  );
};

export default Profilee;
