import SideBar from "../sidebar";
import FAQ from "./FAQ";
import React from "react";

function FaqsPage() {
  return (
    <div className="main-dashboard-container">

     
        <FAQ />
      
    </div>
  );
}

export default FaqsPage;
