import React from "react";
import "./JobSubmitted.scss";
import { useState, useEffect } from "react";
import { json } from "react-router-dom";
import { toast } from "react-toastify";

const JobSubmitted = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/GET_URLS_FROM_MONGO`
      );
      const jsonData = await response.json();
      // const stringToJson = JSON.parse(jsonData);
      console.log("this is the data: ", jsonData);
      if (jsonData.detail) {
        console.log("Here");
        toast.error(`Error ${jsonData.detail}`, {
          toastId: "Data not found"
        } )
      }
      else{

        setData(jsonData.data);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  // {
  //   console.log("This id data: ", data);

  // }
  return (
    <div>
      <h2>Your Submitted Jobs</h2>
      <div className="">
        <table>
          <thead>
            <tr>
              <th>Job Url</th>
              <th></th>
              <th>Date</th>
              <th>Track Job</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item.url}</td>
                  <td></td> {/* Add location data if available */}
                  <td>{item.date}</td>
                  <td>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Job Details
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobSubmitted;

// import React, { useState, useEffect } from 'react';

// const JobSubmitted = () => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true); // Define setLoading state

//     useEffect(() => {
//         fetchData();
//     }, []);

//     const fetchData = async () => {
//         try {
//             const response = await fetch('http://127.0.0.1:8000/api/data');
//             const jsonData = await response.json();
//             console.log(typeof(jsonData));
//             const abc=JSON.parse(jsonData)
//             console.log(abc);
//             // Log API response
//             setData(abc);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//             setLoading(false)
//         } finally {
//             setLoading(false); // Set loading state to false after data is fetched or in case of error
//         }
//     };

//     return (
//         <div className="">
//             <h2>Your Submitted Jobs</h2>
//             <div className="">
//                 {loading ? (
//                     <p>Loading...</p>
//                 ) : (
//                     <table>
//                         <thead>
//                             <tr>
//                                 <th>Submission Date</th>
//                                 <th>Track Job</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {data && data.length>0 && data.map((item, index) => (
//                                 <tr key={index}>
//                                     <td>{item.date}</td>
//                                     <td><a href={item.url} target="_blank" rel="noopener noreferrer">Job Details</a></td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default JobSubmitted;
