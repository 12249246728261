import React, { useEffect, useState } from "react";
import "./navbar.scss";
import cross2 from "../../assets/home/crossBlack.png";
import menuu from "../../assets/home/menuBlack.png";
import navLogo from "../../assets/home/navLogo.png";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, NavLink, useLocation } from "react-router-dom";

const NavMenu = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="desktop-navbar ">
        <div className="logo">
          <img src={navLogo} alt="" />
        </div>
        <div className="nav-items">
          <ul className="text-large fw-semibold">
            <li>
              {" "}
              <a href="#hero">Home</a>
            </li>
            <li>
              {" "}
              <a href="#howtouse">How to use</a>
            </li>
            <li>
              {" "}
              <a href="#keyfeature">Key Features</a>
            </li>
            <li>
              {" "}
              <a href="#pricing">Pricing</a>
            </li>
          </ul>
        </div>
        <NavLink to="/signin">
          <div className="btnContainer">
            <button className="btn-primary-blue text-large fw-semibold">
              Login
            </button>
          </div>
        </NavLink>
      </div>

      <div className="mobile-navbar">
        <NavLink to="/">
          <img className="mobileLogo" src={navLogo} alt="" />
        </NavLink>
        <Button onClick={handleShow} className="menu-btn">
          <img src={menuu} alt="" />
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <NavLink to="/">
                <img src={navLogo} alt="..." className="logostyle" />
              </NavLink>
            </Offcanvas.Title>
            <img onClick={handleClose} src={cross2} alt="Close" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="p-0">
              <li>
                <a href="#hero">Home</a>
              </li>
              <li>
                <a href="#howtouse">How to use</a>
              </li>
              <li>
                <a href="#keyfeature">Key Features</a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
            </ul>

            <NavLink to="/signin">
              <button className="signupBtn">Log In</button>
            </NavLink>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default NavMenu;
