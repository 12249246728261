import React, { useState } from "react";
import "./resetpassword.scss";
import { Link, useNavigate } from "react-router-dom";
import copy from 'clipboard-copy';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OTP = () => {
    const [otp, setOtp] = useState("");
    const [verified, setVerified] = useState(false);
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    const sendOtp = async () => {
        try {
            // Check if OTP is provided
            if (!otp) {
                toast.error("Please enter valid OTP. Your OTP field is empty.");
                return;
            }

            // Endpoint URL
            const url = `${process.env.REACT_APP_BASEURL}/Auth/verify_code`;

            // Request body
            const requestBody = {
                recovery_code: otp // Assuming OTP is the recovery code
            };

            // Making POST request
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody)
            });

            // Parsing response
            const responseData = await response.json();

            // Checking response status
            if (response.ok) {
                // Handle success
                const token = responseData.token;
                // Do something with the token, e.g., store it in state
                setToken(token);
                setVerified(true);
                toast.success("OTP verified successfully!");
            } else {
                // Handle errors
                toast.error("Please enter valid OTP");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Error verifying OTP. Please try again later.");
        }
    };

    const handleEmailChange = (event) => {
        setOtp(event.target.value);
    };

    const copyToken = () => {
        const tokenText = document.querySelector('.mainToken').textContent;
        copy(tokenText);
        toast.success('Token copied to clipboard!');
    };

    return (
        <div className="reset-password-main">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="reset-password-content otp-numbers">
                {!verified ? (
                    <>
                        <h2>Enter your OTP</h2>
                        <div className="otp-inputs">
                            <input
                                name="otp"
                                type="text"
                                autoComplete="off"
                                value={otp}
                                onChange={handleEmailChange}
                            />
                        </div>
                        <div className="buttons">
                            <Link to="/forgetpassword">
                                <div className="go-back-btn white-button">Go back</div>
                            </Link>
                            <div className="submit-button red-button btn-primary" onClick={sendOtp}>
                                Submit
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="Token-main-box">
                        <h2>OTP Verified</h2>
                        <span>Token:</span>
                        <p className="mainToken">{token}</p>
                        <div className="buttons">
                            <div className="submit-button red-button btn-primary" style={{cursor:'pointer'}} onClick={() => navigate("/configPassword")}>
                                Next
                            </div>
                            <button onClick={copyToken} style={{cursor:'pointer'}}>Copy Token</button>
                        </div>
                    </div>
                )}
                <div className="sign-in">
                    <p>Already have an account?</p>
                    <Link to="/signIn"> Sign in</Link>
                </div>
            </div>
        </div>
    );
};

export default OTP;
