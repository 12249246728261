import React from "react";
import "./descriptionCard.scss";

const DescriptionCard = (data) => {
  console.log(data);
  return (
    <>
      <div className={`descriptionCard row ${data.cardSwapping}`}>
        <div className="leftSec col-md-6 col-sm-12 col-lg-6">
          {!data.vid ? (
            <img src={data.cardImg} alt="" className="img-fluid" />
          ) : (
            <video className="img-fluid" autoPlay loop muted>
              <source src={data.cardImg} type="video/mp4"></source>
              </video>
          )}
        </div>
        <div className="rightSec col-md-6 col-sm-12 col-lg-6">
          <p className="text-large fw-semibold ">{data.title}</p>
          <p className="text-small fw-normal">{data.details}</p>
          {data.btnDownload && (
            <button className="text-small fw-normal h-center">
              {data.btnDownload}
            </button>
          )}
          <ul>
            {data.content1 && <li>{data.content1}</li>}

            {data.content2 && <li>{data.content2}</li>}
            {data.content3 && <li>{data.content3}</li>}
            {data.content4 && <li>{data.content4}</li>}
          </ul>
        </div>
      </div>
    </>
  );
};

export default DescriptionCard;
